import { onMounted } from 'vue';
import Bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';

function useTooltip() {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Bootstrap.Tooltip(tooltipTriggerEl, {
            placement: 'right',
            offset: '20,0',
        });
    });
}

export default useTooltip;
